<template>
  <div class="body" v-loading="showLoading">
    <el-form
      size="small"
      :model="configForm"
      label-position="right"
      label-width="185px"
      class="form small-form"
      @submit.native.prevent
      v-if="configForm"
    >
      <el-form-item required label="服务费扣除规则：" prop="cancel_fee">
        <p>
          活动开始前
          <el-input
            type="number"
            v-model.number="configForm.cancel_fee.days_before"
            :class="configForm.cancel_fee.days_before === '' ? 'error' : ''"
          />天以上，取消活动不收服务费
        </p>
        <p>
          活动开始前
          <el-input
            type="number"
            v-model.number="configForm.cancel_fee.hours_before_one"
            :class="
              configForm.cancel_fee.hours_before_one === '' ? 'error' : ''
            "
            @change="hoursChange"
          />小时以上至
          <el-input
            type="number"
            v-model.number="configForm.cancel_fee.days_before"
            :class="configForm.cancel_fee.days_before === '' ? 'error' : ''"
          />天，取消报名收取
          <el-input
            type="number"
            v-model.number="configForm.cancel_fee.deduct_present_one"
            :class="
              configForm.cancel_fee.deduct_present_one === '' ? 'error' : ''
            "
          />
          %服务费
        </p>
        <p>
          活动开始前
          <el-input
            type="number"
            v-model.number="configForm.cancel_fee.hours_before_two"
            :class="
              configForm.cancel_fee.hours_before_two === '' ? 'error' : ''
            "
            @change="hoursChange"
          />
          小时以内，取消报名收取
          <el-input
            type="number"
            v-model.number="configForm.cancel_fee.deduct_present_two"
            :class="
              configForm.cancel_fee.deduct_present_two === '' ? 'error' : ''
            "
          />
          %服务费
        </p>
        <div class="form-tip">
          <span class="el-icon-info" style="font-size: 15px; color: #c0c4cc" />
          <span>免费报名不适用该规则</span>
        </div>
      </el-form-item>
      <el-form-item v-if="isWxApplet" required label="活动开始提醒-订阅消息：" prop="notice_before_activity_start">
        <p>
          活动开始前
          <el-input
            type="number"
            v-model.number="configForm.notice_before_activity_start"
            :class="configForm.notice_before_activity_start === '' ? 'error' : ''"
          />分钟，向用户发送消息通知提醒
        </p>
      </el-form-item>
      <el-form-item required label="活动开始提醒-短信消息：" prop="sms_notice_before_activity_start">
        <p>
          活动开始前
          <el-input
            type="number"
            v-model.number="configForm.sms_notice_before_activity_start"
            :class="configForm.sms_notice_before_activity_start === '' ? 'error' : ''"
          />分钟，向用户发送消息通知提醒
        </p>
      </el-form-item>
      <el-form-item required label="签到配置：" prop="sign">
        <p>
          用户距离活动地点
          <el-input
            type="number"
            v-model.number="configForm.sign.distance"
            :class="configForm.sign.distance === '' ? 'error' : ''"
          />
          米可进行签到，可提前
          <el-input
            type="number"
            v-model.number="configForm.sign.ahead_of_minute"
            :class="configForm.sign.ahead_of_minute === '' ? 'error' : ''"
          />
          分钟进行签到，活动结束后不可签到，如无签到，视为爽约
        </p>
      </el-form-item>
      <el-form-item required label="爽约限制：" prop="break_promise">
        <p>
          爽约超过（包括）
          <el-input
            type="number"
            v-model.number="configForm.break_promise.number"
            :class="configForm.break_promise.number === '' ? 'error' : ''"
          />
          次后将用户拉黑，不可再进行活动报名
        </p>
      </el-form-item>
      <el-form-item required label="取消报名限制：" prop="cancel_sign">
        <p>
          每
          <!-- <el-input type="number" v-model.number="configForm.cancel_sign.month"
            :class="configForm.cancel_sign.month===''?'error':''" /> -->
          <span style="font-weight: bold; white-space: pre">
            {{ configForm.cancel_sign.month }}
          </span>
          个月，每名用户有
          <el-input
            type="number"
            v-model.number="configForm.cancel_sign.number"
            :class="configForm.cancel_sign.number === '' ? 'error' : ''"
          />
          次取消报名的机会(将不扣除服务费)
        </p>
      </el-form-item>
      <el-form-item required label="报名名单排序：" prop="join_users_order_by">
        <el-radio-group v-model="configForm.join_users_order_by">
          <el-radio :label="0">按职务等级从高到低</el-radio>
          <el-radio :label="1">按报名时间倒序</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item required label="留位设置：" prop="reserve.status">
        <el-radio-group v-model="configForm.reserve.status">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
        <div class="form-tip">
          <span class="el-icon-info" style="font-size: 15px; color: #c0c4cc" />
          <span>开启留位后，用户可先报名，后填写报名表单和资料填写</span>
        </div>

        <el-form
          v-if="configForm.reserve.status"
          :model="configForm.reserve"
          style="margin-top: 32px"
          size="small"
        >
          <el-form-item label="留位时间：">
            <p>
              报名后
              <el-input
                type="number"
                v-model.number="configForm.reserve.minutes"
                :class="configForm.reserve.minutes === '' ? 'error' : ''"
              />分钟内完善报名表单及资料填写，超时取消报名
            </p>
          </el-form-item>
          <el-form-item label="留位提醒：">
            <p>
              距离留位时间结束还有
              <el-input
                type="number"
                v-model.number="configForm.reserve.msg_minutes"
                :class="configForm.reserve.msg_minutes === '' ? 'error' : ''"
              />分钟提醒用户完善填写
            </p>
          </el-form-item>
        </el-form>
      </el-form-item>
    </el-form>
    <FixedActionBar>
      <el-button type="primary" @click="handleSave" :loading="saveLoading"
        >保存</el-button
      >
    </FixedActionBar>
  </div>
</template>

<script>
import {
  ActivityBasisConfig,
  SaveBasisConfig,
} from '../../api/activity-config/base-config'
import FixedActionBar from '@/base/layout/FixedActionBar'
export default {
  data() {
    return {
      configForm: null,
      showLoading: false,
      saveLoading: false,
    }
  },
  computed: {
    isWxApplet() {
      return this.$store.getters.tenant.features.includes('wx_applet')
    }
  },
  methods: {
    getActivityBasisConfig() {
      this.showLoading = true
      ActivityBasisConfig()
        .then((res) => {
          this.configForm = Object.assign(
            {},
            {
              cancel_sign: {
                month: 0,
                number: 0,
              },
              sign: {
                distance: 0,
                ahead_of_minute: 0,
              },
              cancel_fee: {
                days_before: 0,
                hours_before_one: 0,
                deduct_present_one: 0,
                hours_before_two: 0,
                deduct_present_two: 0,
              },
              reserve: {
                //留位设置
                status: 0,
                minutes: 30,
                msg_minutes: 10,
              },
            },
            res.data
          )
          this.showLoading = false
        })
        .catch((err) => {
          this.showLoading = false
        })
    },
    hoursChange(value) {
      this.configForm.cancel_fee.hours_before_one =
        this.configForm.cancel_fee.hours_before_two = value
    },
    // 保存
    handleSave() {
      this.saveLoading = true
      const data = {}
      for (let key in this.configForm) {
        for (let k in this.configForm[key]) {
          if (this.configForm[key][k] === '') {
            this.$message.error('请填写完整信息')
            this.saveLoading = false
            return
          }
        }
      }
      data.config = { ...this.configForm }
      SaveBasisConfig(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.saveLoading = false
        })
        .catch((err) => {
          this.saveLoading = false
        })
    },
  },
  created() {
    this.getActivityBasisConfig()
  },
  components: { FixedActionBar },
}
</script>

<style lang="scss" scoped>
.body {
  position: relative;
  .el-input {
    width: 50px;
  }
  p + p {
    margin-top: 20px;
  }

  .error {
    ::v-deep.el-input__inner {
      border-color: red;
    }
  }

  .bottom-btn {
    padding: 0 0 10px 20px;
    position: absolute;
    bottom: 0;
  }
}
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
</style>
